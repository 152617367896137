import { OrderDetails } from '../utils/types';
import {
  FulfillmentMethodType,
  NormalizedOrder,
} from '@wix/restaurants-normalizers';

const getFormattedDate = (date: Date, regionalLocale?: string) => {
  const dateConfig: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: '2-digit',
    day: '2-digit',
  };

  return date.toLocaleDateString(regionalLocale, dateConfig);
};
const getFormattedTime = (date: Date, regionalLocale?: string) => {
  const timeConfig: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24',
  };

  return date.toLocaleTimeString(regionalLocale, timeConfig);
};

const getDueBy = (from?: Date, to?: Date, regionalLocale?: string): string => {
  const isRange = from && to && from.getTime() !== to.getTime();

  if (isRange) {
    return `${getFormattedDate(from, regionalLocale)}, ${getFormattedTime(
      from,
      regionalLocale,
    )} - ${getFormattedTime(to, regionalLocale)}`;
  }

  return `${getFormattedDate(
    (from ?? to)!,
    regionalLocale,
  )}, ${getFormattedTime((from ?? to)!, regionalLocale)}`;
};

export function getOrderDetails(
  normalizedOrder: NormalizedOrder,
  regionalLocale?: string,
  isOrderWithoutMethodEnable = false,
): OrderDetails[] {
  const orderDetails: OrderDetails[] = [];
  const defaultTime = !isOrderWithoutMethodEnable ? new Date() : undefined;
  const {
    fulfillmentMethodType,
    order,
    formattedPickupAddress,
    formattedDeliveryAddress,
    fulfillmentDateTimeFrom: from = defaultTime,
    fulfillmentDateTimeTo: to = defaultTime,
  } = normalizedOrder;
  const pickupInstruction =
    fulfillmentMethodType === FulfillmentMethodType.PICKUP
      ? order.shippingInfo?.logistics?.instructions
      : undefined;
  const orderNote = order.buyerNote;

  switch (fulfillmentMethodType) {
    case FulfillmentMethodType.PICKUP:
      if (formattedPickupAddress) {
        orderDetails.push({
          _id: `${orderDetails.length}`,
          titleKey: 'tracker.page.pickup-details.address.title',
          value: formattedPickupAddress,
        });
      }
      break;
    case FulfillmentMethodType.DELIVERY:
      if (formattedDeliveryAddress) {
        orderDetails.push({
          _id: `${orderDetails.length}`,
          titleKey: 'tracker.page.delivery-details.address.title',
          value: formattedDeliveryAddress,
        });
      }
      break;
    case FulfillmentMethodType.NONE:
      if (isOrderWithoutMethodEnable) {
        orderDetails.push({
          _id: `${orderDetails.length}`,
          titleKey:
            'tracker.page.unknown-fulfillment-details.created-date.title',
          value: `${getFormattedDate(
            order.createdDate,
            regionalLocale,
          )}, ${getFormattedTime(order.createdDate, regionalLocale)}`,
        });
      }
      break;
  }

  if (from || to) {
    const dueBy = getDueBy(from, to, regionalLocale);
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey:
        fulfillmentMethodType === FulfillmentMethodType.PICKUP
          ? 'tracker.page.pickup-details.due-by.title'
          : 'tracker.page.delivery-details.due-by.title',
      value: dueBy,
    });
  }

  if (pickupInstruction) {
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: 'tracker.page.pickup-details.instruction.title',
      value: pickupInstruction,
    });
  }

  if (orderNote) {
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: 'tracker.page.order-details.order-note.title',
      value: orderNote,
    });
  }

  return orderDetails;
}
