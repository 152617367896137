import model from './model';
import { initOrderState } from './orderState';
import { getOrderInfo } from '../../api/orderClient';
import { getOrderDetails } from './orderDetails/orderDetails';
import { selectors, states } from './selectors';
import { OrderDetails } from './utils/types';
import { oloLiveSiteTrackerPageLoaded } from '@wix/bi-logger-restaurants/v2';
import { TrackerPageLoadedParams } from '@wix/restaurants-bi';
import {
  FulfillmentMethodType,
  orderNormalizer,
} from '@wix/restaurants-normalizers';
import { EcomOrderStatus } from '@wix/restaurants-types';
import {
  mapFulfillmentMethodBI,
  mapMethodDetailsTitleKey,
} from './utils/fulfillmentMethod';
import { Specs } from '../../constants/specs';

export default model.createController(
  ({ $bind, $w, $widget, initState, flowAPI }) => {
    const orderId =
      flowAPI.controllerConfig?.wixCodeApi?.location?.query?.orderId;
    const t = flowAPI.translations.t;
    const experiments = flowAPI.experiments;
    const isOrderWithoutMethodEnable = experiments.enabled(
      Specs.ORDER_WITHOUT_METHOD,
    );
    const logger = flowAPI.bi;

    const { orderInfo, updateOrderInfo } = initOrderState(initState);

    return {
      pageReady: () => {
        // For not indexable in SEO - https://wix.slack.com/archives/C01HMHGN26S/p1730110081230199?thread_ts=1730032147.814549&cid=C01HMHGN26S
        flowAPI.controllerConfig?.wixCodeApi?.seo?.renderSEOTags({
          itemType: 'THANK_YOU_PAGE',
          itemData: {},
        });
        $w(selectors.RestaurantDetailsBox).collapse();
        $bind(selectors.errorStateText, {
          text: () => t('tracker.error.text'),
        });

        if (flowAPI.environment.isEditor || flowAPI.environment.isPreview) {
          $w(selectors.stateBox).changeState(states.ready);
          return;
        }
        if (!orderId) {
          $w(selectors.stateBox).changeState(states.error);
        } else {
          $w(selectors.stateBox).changeState(states.loader);

          getOrderInfo(flowAPI.essentials.httpClient, orderId)
            .then(async (order) => {
              const normalizedOrder = orderNormalizer({
                order,
                options: {
                  defaultContactName: '',
                },
              });

              const {
                restaurantsOrderStatus,
                isCanceled,
                fulfillmentMethodType,
                fulfillmentDateTimeFrom: from,
                fulfillmentDateTimeTo: to,
              } = normalizedOrder;
              const orderStatus = isCanceled
                ? EcomOrderStatus.CANCELED
                : restaurantsOrderStatus;

              $w(selectors.trackerWidget).setStatus(orderStatus);
              await $w(selectors.trackerWidget).initDuplexer(order.id);

              let method: FulfillmentMethodType;
              if (isOrderWithoutMethodEnable) {
                method = fulfillmentMethodType;
              } else {
                if (fulfillmentMethodType === FulfillmentMethodType.NONE) {
                  method = FulfillmentMethodType.DELIVERY;
                } else {
                  method = fulfillmentMethodType;
                }
              }

              updateOrderInfo({
                orderNumber: order.number,
                orderDetails: getOrderDetails(
                  normalizedOrder,
                  flowAPI.controllerConfig.wixCodeApi.site.regionalSettings,
                  isOrderWithoutMethodEnable,
                ),
                method,
              });
              $w(selectors.stateBox).changeState(states.ready);

              if (logger) {
                const fromTime = from?.getTime();
                const toTime = to?.getTime();
                const fulfilledTimeTo =
                  fromTime !== toTime ? toTime : undefined;
                const fulfillmentType =
                  mapFulfillmentMethodBI[fulfillmentMethodType];

                const eventParams: TrackerPageLoadedParams = {
                  orderId: order.id,
                  orderStatus,
                  fulfilledTimeFrom: fromTime,
                  fulfilledTimeTo,
                  fulfillmentType,
                };

                logger.report(oloLiveSiteTrackerPageLoaded(eventParams));
              }
            })
            .catch(() => {
              $w(selectors.stateBox).changeState(states.error);
            });
        }
        $bind(selectors.title, { text: () => t('tracker.page.title') });
        $bind(selectors.orderNumberLabel, {
          text: () => t('tracker.page.order-number.label'),
        });
        $bind(selectors.orderNumber, { text: () => orderInfo.orderNumber });
        $bind(selectors.methodDetailsRepeater, {
          data: () => orderInfo.orderDetails,
        });
        $bind(selectors.methodDetailsTitle, {
          text: () =>
            orderInfo.method !== undefined
              ? t(mapMethodDetailsTitleKey[orderInfo.method])
              : '',
        });
        $w(selectors.methodDetailsRepeater).onItemReady(
          ($item, itemData: OrderDetails) => {
            $item(selectors.orderDetailsItemTitle).text = t(itemData.titleKey);
            $item(selectors.orderDetailsItemValue).text = itemData.value;
          },
        );

        $widget.fireEvent('widgetLoaded', {});
      },
      exports: {},
    };
  },
);
